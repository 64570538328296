/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { add } from 'date-fns';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DashBoardHeader from '../../../components/dashboard_components/DashBoardHeader';
import CQContainer from '../../../components/layout/CQContainer';
import AppColors from '../../../constants/AppColors';
import { CUSTOM } from '../../../constants/constants';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { withProvider } from '../../../hoc/withProvider';
import DepartmentProvider, {
  DepartmentContext,
} from '../../../providers/DepartmentProvider';
import departmentDashboardRepository from '../../../repositories/DepartmentRepository';
import {
  dateFormat,
  replaceRoute,
} from '../../../utils/common.utils';
import AIRiskMonitor from '../components/AIRiskMonitor';
import TilePopup from '../components/TilePopup';
import DAuditChartAccuracy from './sections/DAuditChartAccuracy';
import DChartAccuracy from './sections/DChartAccuracy';
import DCqAiRevenueOpporturinty from './sections/DCqAiRevenueOpporturinty';
import DOutstandingRevenue from './sections/DOutstandingRevenue';
import DPhysicianTable from './sections/DPhysicianTable';
import Dproviders from './sections/Dproviders';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const DEPARTMENT_CQ_AI_RISK_OPPORTURINITY =
  'DEPARTMENT_CQ_AI_RISK_OPPORTURINITY';

function DepartmentDBContainer() {
  const params = useParams();
  const departmentContext = useContext(DepartmentContext);
  const [departmentName, setDepartmentName] = useState('');
  const [isFetching, ifState] = useBoolean(false);
  const oldFilter = JSON.parse(localStorage.getItem('selectedFilter'));
  const [filter, setFilter] = React.useState(oldFilter ? oldFilter : 'month');

  const [isTileModalOpen, itmState] = useBoolean(false);
  const [currentTile, setCurrentTile] = useState(null);
  const current = new Date();
  current.setMonth(current.getMonth() - 1);

  const getDepartmentName = async () => {
    try {
      ifState.on();
      const response = await departmentDashboardRepository.getDepartmentName(
        params.departmentId
      );
      setDepartmentName(response?.department_name);
    } catch (error) {
    } finally {
      ifState.off();
    }
  };

  const selectedDates = JSON.parse(
    window.localStorage.getItem('customDateFilter')
  );

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  React.useEffect(() => {
    getDepartmentName();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (oldFilter !== filter) {
      localStorage.setItem('selectedFilter', JSON.stringify(filter));
      if (filter === 'custom') {
        const { start_date, end_date, previous_start_date, previous_end_date } =
          fromDate;
        localStorage.setItem(
          'customDateFilter',
          JSON.stringify({
            start_date,
            end_date,
            previous_start_date,
            previous_end_date,
          })
        );
      } else {
        localStorage.removeItem('customDateFilter');
      }
    }
  }, [filter]);

  const yesterday = add(new Date(), {
    days: -1,
  });
  const [fromDate, setFromDate] = React.useState({
    start_date: add(yesterday, {
      days: -30,
    }),
    end_date: yesterday,
    previous_start_date: add(yesterday, {
      days: -60,
    }),
    previous_end_date: add(yesterday, {
      days: -30,
    }),
  });
  const fetchRUVOpportunity = async () => {
    const filterParams = {};
    filterParams.filter = filter.toLowerCase();
    if (filter === CUSTOM.toLowerCase()) {
      setCustomDates(filterParams);
      // setFilters(
      //   filterParams,
      //   dateFormat(fromDate.previous_start_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.previous_end_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.start_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.end_date, 'yyyy-MM-dd')
      // );
      delete filterParams.filter;
    }
    filterParams.department_id = params.departmentId;
    await departmentContext.getAIRisk(filterParams);
  };

  React.useEffect(() => {
    fetchRUVOpportunity();
  }, [fromDate, filter]);

  const tileLoader = () => {
    return (
      <Stack p={5}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderTileHeader = (title, infoContent, noDate) => {
    return (
      <HStack justifyContent={'space-between'}>
        {title ? (
          <Text
            fontSize={'sm'}
            color={AppColors.primary}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {title}
          </Text>
        ) : null}
        {infoContent ? (
          <Tooltip p={3} bg="whiteAlpha.900"
            color="black"
            borderRadius="md"
            border="1px solid #43dde0" label={
              <Box>
                {infoContent}
              </Box>
            } placement="top-end">
            <Icon as={InfoOutlineIcon} w={4} h={4} cursor="pointer" _hover={{ color: AppColors.secondary, transition: 'color 0.3s ease-in-out' }} color={AppColors.primary} />
          </Tooltip>
        ) : null}
        {/* <Text fontSize={'sm'}>
          {filter === DAY
            ? dateFormat(fromDate.start_date, 'MM/dd/yyyy')
            : `${dateFormat(fromDate.start_date, 'MM/dd/yyyy')} -
            ${dateFormat(fromDate.end_date, 'MM/dd/yyyy')}`}
        </Text> */}
      </HStack>
    );
  };
  const tileFailure = (title, infoContent, message) => {
    return (
      <Box
        p={isTileModalOpen ? 0 : 5}
        bg={'white'}
        borderRadius="md"
        width={'100%'}
      >
        {renderTileHeader(title, infoContent)}
        <Box mt={10} textAlign="center" justifyContent={'center'}>
          <Text>
            {message ? message : 'Something went wrong, Please try again later'}
          </Text>
        </Box>
      </Box>
    );
  };
  const renderCQAIRiskMonitor = () => {
    if (departmentContext.isAIRiskLoading) {
      return tileLoader();
    }

    if (departmentContext.error['AI_RISK']) {
      return tileFailure(
        'Revenue Cycle Temperature Gauge',
        (
          <>
            <p>
              <b>Revenue Cycle Temperature Gauge</b> represents the overall health of your revenue cycle. High temperatures indicate potential compliance risks, while low temperatures suggest possible revenue leaks. You can set specific parameters in the 'Practice Info' tab to customize this gauge.
            </p>
          </>
        ),
        departmentContext.error['AI_RISK']
      );
    }

    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        {renderTileHeader('Revenue Cycle Temperature Gauge', (
          <>
            <p>
              <b>Revenue Cycle Temperature Gauge</b> represents the overall health of your revenue cycle. High temperatures indicate potential compliance risks, while low temperatures suggest possible revenue leaks. You can set specific parameters in the 'Practice Info' tab to customize this gauge.
            </p>
          </>
        ))}
        <Box mt={4}>
          <AIRiskMonitor
            data={departmentContext.aiRisk}
            tile={isTileModalOpen}
          />
        </Box>
      </Box>
    );
  };

  const renderHeaderComponent = () => {
    let backRoute = ROUTES.DASHBOARD;
    if (params?.hospitalId) {
      backRoute = replaceRoute(ROUTES.HOSPITAL_DASHBOARD, {
        hospitalId: params.hospitalId,
      });
    }

    return (
      <DashBoardHeader
        isFetchingHospital={isFetching}
        titleName={departmentName}
        setFromDate={setFromDate}
        fromDate={fromDate}
        navigationRoute={backRoute}
        setFilter={setFilter}
        filter={filter}
        objectWithDate={departmentContext.cqScore}
      />
    );
  };

  const renderFirstRowComponent = () => {
    return (
      <Box>
        <Grid
          // templateColumns="repeat(3, 1fr)"
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          mt={4}
          gap={4}
        >
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <DChartAccuracy
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
          <GridItem
            cursor="pointer"
            bgColor={'#fff'}
            borderRadius={'lg'}
            shadow={'md'}
            onClick={e => {
              if (e.target.name !== 'aiData') {
                setCurrentTile(DEPARTMENT_CQ_AI_RISK_OPPORTURINITY);
                itmState.on();
              }
            }}
          >
            {renderCQAIRiskMonitor()}
          </GridItem>
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <DCqAiRevenueOpporturinty
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  };

  const renderSecondRowComponent = () => {
    return (
      <Box>
        <Grid
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
          }}
          // templateColumns="repeat(2, 1fr)"
          mt={4}
          gap={4}
        >
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <DOutstandingRevenue
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <Dproviders
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  };

  const renderAuditChartAccuracy = () => {
    return (
      <DAuditChartAccuracy
        defaultStartDate={fromDate.end_date}
        defaultEndDate={fromDate.start_date}
        startDate={fromDate.previous_start_date}
        endDate={fromDate.previous_end_date}
        filter={filter}
      />
    );
  };

  const renderPhysicianTable = () => {
    return (
      <DPhysicianTable
        defaultStartDate={fromDate.end_date}
        defaultEndDate={fromDate.start_date}
        startDate={fromDate.previous_start_date}
        endDate={fromDate.previous_end_date}
        filter={filter}
      />
    );
  };

  const TILE_MODAL_BODY = {
    DEPARTMENT_CQ_AI_RISK_OPPORTURINITY: renderCQAIRiskMonitor,
  };

  const renderGraphModal = () => {
    return (
      <TilePopup
        renderBody={currentTile ? TILE_MODAL_BODY[currentTile] : () => null}
        isOpen={isTileModalOpen}
        onClose={() => {
          itmState.off();
        }}
      />
    );
  };

  return (
    <CQContainer>
      {renderHeaderComponent()}
      {renderFirstRowComponent()}
      {renderSecondRowComponent()}
      {renderAuditChartAccuracy()}
      {renderPhysicianTable()}
      {renderGraphModal()}
    </CQContainer>
  );
}

export default withProvider(DepartmentProvider, DepartmentDBContainer);
