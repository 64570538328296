import { useBoolean } from '@chakra-ui/react';
import { useToastr } from '@laxmimanogna/code-quick-components';
import React, { createContext, useState } from 'react';
import dashboardRepository from '../repositories/DashboardRepository';
import physicianRepository from '../repositories/PhysiciansRepository';

export const PhysicianContext = createContext({
  getPhysician: async physicianId => { },
  physicianData: {},
  isGettingphysician: false,
  getRVUOpp: async () => { },
  rvuOpp: {},
  isRvuLoading: false,
  isFileLoading: false,
  getRVUOppDropdown: async () => { },
  dropdown: [],
  setRVUOppFilter: () => { },
  rvuOppFilter: {},
  error: {},
  showComparison: false,
  setShowComparison: () => { },

  cqScore: {},
  setCQScore: () => {},
});

function PhysicianProvider(props) {
  const [isGettingphysician, igpState] = useBoolean(false);
  const [physicianData, setPhysicianData] = React.useState({});
  const [rvuOpp, setRvuOpp] = React.useState({});
  const [isRvuLoading, iRVUState] = useBoolean(false)
  const [isAIRiskLoading, iarlState] = useBoolean(false)
  const [isFileLoading, iflState] = useBoolean(false)
  const [dropdown, setDropdown] = useState([])
  const [rvuOppFilter, setRVUOppFilter] = useState({ rvu_opportunity_type: '' })

  const [cqScore, setCQScore] = useState({'start_date':null, 'end_date':null})
  const [aiRisk, setAIRisk] = useState({})
  const toast = useToastr();
  const [error, setError] = useState({
    TOTAL_UPLOADS: '',
    CQ_SCORE: '',
    RVU_OPPORTUNITY: '',
    AI_RISK: ''
  })
  const [showComparison, setShowComparison] = useState(false)

  const getPhysician = async physicianId => {
    try {
      igpState.on();
      const response = await physicianRepository.getPhysician(physicianId);
      setPhysicianData(response);
      return response;
    } catch (error) {
      toast.showError({
        description: error.toString(),
      });
    } finally {
      igpState.off();
    }
  };

  const getRVUOpp = async (filterParams) => {
    try {
      iRVUState.on()
      const response = await physicianRepository.getRUVoppurtunity(
        {
          ...filterParams,
          ...rvuOppFilter
        }
      );
      setRvuOpp(response);
    } catch (err) {
      // console.log(err)
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, RVU_OPPORTUNITY: response.message }))
      }
    } finally {
      iRVUState.off()
    }
  }

  const getAIRisk = async (filter) => {
    try {
      iarlState.on();
      const response = await dashboardRepository.getAIRisk(filter);
      setAIRisk({ ...response, label: 'Physicians' })
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, AI_RISK: response.message }))
      }
    } finally {
      iarlState.off();
    }
  }

  const getRVUOppDropdown = async (queryParams) => {
    try {
      iflState.on();
      const response = await dashboardRepository.getRVUOppDropdown(queryParams);
      let temp = []
      if (Object.keys(response).length) {
        Object.keys(response).forEach(r => {
          temp.push({
            label: response[r],
            value: r
          })
        })
      }
      setDropdown(temp);
    } catch (err) {
      toast.showError({
        description: `Something went wrong.`,
      });
    } finally {
      iflState.off();
    }
  }
  const mContext = {
    getPhysician,
    physicianData,
    isGettingphysician,
    getRVUOpp,
    rvuOpp,
    isRvuLoading,
    getAIRisk,
    aiRisk,
    isAIRiskLoading,
    isFileLoading,

    getRVUOppDropdown,
    dropdown,
    setRVUOppFilter,
    rvuOppFilter,
    error,
    showComparison,
    setShowComparison,

    cqScore,
    setCQScore,
  };
  return (
    <PhysicianContext.Provider value={mContext}>
      {props.children}
    </PhysicianContext.Provider>
  );
}

export default PhysicianProvider;
