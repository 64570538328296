import { useBoolean, useToastr } from "@laxmimanogna/code-quick-components";
import { add, format } from "date-fns";
import { createContext, useState } from "react";
import { getDateInterval } from "../pages/dashboard/getDateInterval";
import auditSheetRepository from "../repositories/AuditSheetRepository";
import batchDashboardRepository from "../repositories/BatchRepository";
import dashboardRepository from "../repositories/DashboardRepository";

export const BatchContext = createContext({
    getAllUsers: async () => { },
    allUsers: {},
    isRVUOppLoading: false,
    rvuOpp: {},
    getRVUOpp: async () => { },
    filterParams: {},
    handleApplyFilter: () => { },
    getAIRisk: () => { },
    aiRisk: {},
    isAIRiskLoading: false,
    isFileLoading: false,
    getRVUOppDropdown: async () => { },
    dropdown: [],
    setRVUOppFilter: () => { },
    rvuOppFilter: {},
    isUploadFetching: false,
    uploadActivityData: null,
    getActivityData: async () => { },
    isCQScoreFetching: false,
    cqScore: null,
    fetchCqScore: async () => { },
    isRevenueOppScoreFetching: false,
    revenueOpporturintyScore: null,
    fetchRevenueOpporturinityScore: async () => { },
    isOppBreakdownFetching: false,
    opporturnityBreakdown: null,
    getOutstandingRevenueData: async () => { },
    isChartAccuracyLoading: false,
    getChartAccuracy: async () => { },
    chartAccuracyData: null,
    chartAccuracyFilters: {},
    setChartAccuracyFilters: () => { },
    rebuttalData: null,
    fetchRebuttalData: async () => { },
    isRebuttalLoading: false,
    setRebuttalData: () => { }
});

const BatchProvider = props => {
    const toast = useToastr();
    const [allUsers, setAllUsers] = useState([])
    const [isRVUOppLoading, irvuoState] = useBoolean(false)

    const [rvuOpp, setRvuOpp] = useState({})
    const [isAIRiskLoading, iarlState] = useBoolean(false)
    const [isFileLoading, iflState] = useBoolean(false)
    const [dropdown, setDropdown] = useState([])
    const [rvuOppFilter, setRVUOppFilter] = useState({ rvu_opportunity_type: '' })

    const [aiRisk, setAIRisk] = useState({})

    const [isUploadFetching, iufState] = useBoolean(false);
    const [uploadActivityData, setUploadActivityData] = useState(null);
    const [isCQScoreFetching, icqsfState] = useBoolean(false);
    const [cqScore, setCqScore] = useState(null);
    const [isRevenueOppScoreFetching, irosState] = useBoolean(false);
    const [revenueOpporturintyScore, setRevenueOpporturintyScore] =
        useState(null);
    const [isOppBreakdownFetching, iobfState] = useBoolean(false);
    const [opporturnityBreakdown, setOpporturnityBreakdown] = useState(null);
    const [chartAccuracyFilters, setChartAccuracyFilters] = useState({});
    const [isChartAccuracyLoading, icaState] = useBoolean(false)
    const [chartAccuracyData, setChartAccuracyData] = useState(null);
    const [isRebuttalLoading, irState] = useBoolean(false)
    const [rebuttalData, setRebuttalData] = useState(null);
    const yesterday = add(new Date(), {
        days: -1,
    });
    const selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));
    let initialFilterParams = { batch_id: '' };
    if (selectedFilter) {
        if (selectedFilter.toLowerCase() === 'custom') {
            const selectedCustomDates = JSON.parse(
                window.localStorage.getItem('customDateFilter')
            );
            initialFilterParams = {
                label: 'custom',
                start_date: new Date(selectedCustomDates.start_date),
                end_date: new Date(selectedCustomDates.end_date),
                previous_start_date: new Date(selectedCustomDates.previous_start_date),
                previous_end_date: new Date(selectedCustomDates.previous_end_date),
            };
        } else {
            const data = getDateInterval(selectedFilter);
            initialFilterParams = {
                label: selectedFilter,
                ...data,
            };
        }
    } else {
        initialFilterParams = {
            label: 'month',
            start_date: add(yesterday, {
                days: -30,
            }),
            end_date: yesterday,
            previous_start_date: add(yesterday, {
                days: -60,
            }),
            previous_end_date: add(yesterday, {
                days: -30,
            }),
        };
    }
    const [filterParams, setFilterParams] = useState(
        Object.assign({}, initialFilterParams)
    );
    const handleApplyFilter = selectedFilters => {
        setFilterParams(selectedFilters);
    };

    const getFilterParams = () => {
        const queryParams = {};
        const { start_date, end_date, label } = filterParams;

        if (label) queryParams['filter'] = label.toLowerCase();
        if (filterParams.batch_id) queryParams['batch_id'] = filterParams.batch_id;

        if (filterParams?.label?.toLowerCase() === 'custom') {
            queryParams['start_date'] = format(start_date, 'yyyy-MM-dd');
            queryParams['end_date'] = format(end_date, 'yyyy-MM-dd');
            queryParams['previous_start_date'] = format(filterParams.previous_start_date, 'yyyy-MM-dd');
            queryParams['previous_end_date'] = format(filterParams.previous_end_date, 'yyyy-MM-dd');
            delete queryParams['filter']
        }
        return queryParams;
    };

    async function getAllUsers() {
        try {
            const response = await auditSheetRepository.getAllUsers()
            const userData = response.map(r => {
                return {
                    id: r.id,
                    first_name: r.first_name,
                    last_name: r.last_name
                }
            })
            setAllUsers([...userData])
        } catch (err) {
            toast.showError({
                description: err.toString(),
            });
        }
    }

    const getRVUOpp = async () => {
        try {
            irvuoState.on()
            const filter = getFilterParams()
            const response = await dashboardRepository.getRVUOpp({ ...filter, ...rvuOppFilter })
            setRvuOpp(response)
        } catch (err) {
            setRvuOpp({})
        } finally {
            irvuoState.off()
        }
    }


    const getAIRisk = async () => {
        try {
            iarlState.on();
            const filter = getFilterParams()
            const response = await dashboardRepository.getAIRisk(filter);
            setAIRisk({ ...response, label: 'Providers' })
        } catch (err) {
            toast.showError({
                description: `Something went wrong.`,
            });
        } finally {
            iarlState.off();
        }
    }

    const getRVUOppDropdown = async (queryParams) => {
        try {
            iflState.on();
            const filter = getFilterParams()
            const response = await dashboardRepository.getRVUOppDropdown({ ...queryParams, ...filter });
            let temp = []
            if (Object.keys(response).length) {
                Object.keys(response).forEach(r => {
                    temp.push({
                        label: response[r],
                        value: r
                    })
                })
            }
            setDropdown(temp);
        } catch (err) {
            toast.showError({
                description: `Something went wrong.`,
            });
        } finally {
            iflState.off();
        }
    }

    const getActivityData = async () => {
        try {
            iufState.on();
            const filter = getFilterParams()
            const response = await batchDashboardRepository.getUploadChartActivity({
                ...filter
            });
            setUploadActivityData(response);
            return response;
        } catch (error) {
            setUploadActivityData({});
        } finally {
            iufState.off();
        }
    };


    const fetchCqScore = async () => {
        try {
            icqsfState.on();
            const filter = getFilterParams()
            const response = await batchDashboardRepository.getBatchCQScore({
                ...filter
            });
            setCqScore(response);
            return response;
        } catch (error) {
            setCqScore(null);
        } finally {
            icqsfState.off();
        }
    };


    const fetchRevenueOpporturinityScore = async () => {
        try {
            irosState.on();
            const filter = getFilterParams()
            const response = await batchDashboardRepository.getoutStandingRevOpp({
                ...filter
            });
            setRevenueOpporturintyScore(response);
            return response;
        } catch (error) {
            setRevenueOpporturintyScore(null);
        } finally {
            irosState.off();
        }
    };

    const getOutstandingRevenueData = async () => {
        try {
            iobfState.on();
            const filter = getFilterParams()
            const response =
                await batchDashboardRepository.getOutStandingRevOppBreakDown({
                    ...filter
                });
            setOpporturnityBreakdown(response);
            return response;
        } catch (error) {
            setOpporturnityBreakdown(null);
        } finally {
            iobfState.off();
        }
    };

    const getChartAccuracy = async () => {
        try {
            icaState.on();
            const filter = getFilterParams()
            const response = await batchDashboardRepository.getChartAccuracyData(
                { ...filter, ...chartAccuracyFilters }
            );

            setChartAccuracyData(response);
            //   return response;
        } catch (error) {
            setChartAccuracyData(null);
        } finally {
            icaState.off();
        }
    }

    const fetchRebuttalData = async () => {
        try {
            irState.on();
            const filter = getFilterParams()

            const response = await batchDashboardRepository.getAuditRebuttalComments(
                filter
            );
            setRebuttalData(response);
        } catch (error) {
            setRebuttalData(null);
        } finally {
            irState.off();
        }
    }

    const mContext = {
        allUsers,
        getAllUsers,
        isRVUOppLoading,
        rvuOpp,
        getRVUOpp,
        filterParams,
        handleApplyFilter,
        getAIRisk,
        aiRisk,
        isAIRiskLoading,
        isFileLoading,
        getRVUOppDropdown,
        dropdown,
        setRVUOppFilter,
        rvuOppFilter,
        isUploadFetching,
        uploadActivityData,
        getActivityData,
        isCQScoreFetching,
        cqScore,
        fetchCqScore,
        isRevenueOppScoreFetching,
        revenueOpporturintyScore,
        fetchRevenueOpporturinityScore,
        isOppBreakdownFetching,
        opporturnityBreakdown,
        getOutstandingRevenueData,
        isChartAccuracyLoading,
        getChartAccuracy,
        chartAccuracyData,
        chartAccuracyFilters,
        setChartAccuracyFilters,
        rebuttalData,
        fetchRebuttalData,
        isRebuttalLoading,
        setRebuttalData
    };

    return (
        <BatchContext.Provider value={mContext}>
            {props.children}
        </BatchContext.Provider>
    );

};
export default BatchProvider;
